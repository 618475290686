import '../App.css';
import React, {useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { logEvent } from "firebase/analytics";
import {FacebookIcon} from "react-share";
import { getText } from "../Libraries/LangLib";

function PrizeModal({modalShown, close, analytics, streak, lang }) {

    const [phoneNum, setPhoneNum] = useState('');
    const [disabled, setDisabled] = useState(false);

    const sendEntry = () => {
        logEvent(analytics, 'prize_entered', {
            phone: phoneNum
        });
        localStorage.setItem('prize_entered', phoneNum);
        setDisabled(true);
        // FOR NOW: Until GA report is 100%
        // Send slack message when someone enters the prize
        fetch("https://hooks.slack.com/services/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", {
            body: JSON.stringify({"text": phoneNum + " is in the draw!"}),
            method: "POST"
        });
    }

    const disableSubmit = () => {
        let submittedPhone = localStorage.getItem('prize_entered');
        if (submittedPhone !== null) {
            return true;
        }
        return false;
    }

    const getPlaceholder = () => {
        let submittedPhone = localStorage.getItem('prize_entered');
        if (submittedPhone !== null) {
            return submittedPhone;
        }
        return getText(lang, 'prize', 0);
    }

    useEffect(() => {
        setDisabled(disableSubmit());
	}, []);

    const buttonStyle = () => {
        let style = {
            color: 'black',
        }
        if (disabled) {
            style.color = 'gray'
        }
        style.textAlign = lang === 'ar' ? 'right' : 'left'
        return style;
    }

    const chooseBox = () => {
        if (streak >= 15) {
            return (
                <Box sx={modalStyle}>
                    <h2>🤩🤩🥳🥳💥💥🤲🤲</h2>
                    <h2>{getText(lang, 'prize', 1)}</h2>
                    <input type="text" name="name" className="prize-input" onChange={e => setPhoneNum(e.target.value)} placeholder={getPlaceholder()} disabled={disabled} style={buttonStyle()}  />
                    <div className='prize-submit-button-div'>
                        <input disabled={disabled} type="submit" value={getText(lang, 'prize', 2)} onClick={sendEntry} className="prize-submit-button" style={buttonStyle()} />
                    </div>
                    <p>{getText(lang, 'prize', 3)}</p>
                    <a href="https://www.facebook.com/profile.php?id=100090742102599" target="_blank"><FacebookIcon size={32} round /></a>
                </Box>
            );
        } else {
            return(
                <Box sx={modalStyle}>
                    <h1>🏆🏆</h1>
                    <p>{getText(lang, 'prize', 4)}</p>
                </Box>
            );
        }
    }

    const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '18em',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		outline: 0,
		textAlign: lang === 'ar' ? 'right' : 'left'
	};

    return (
        <Modal
            open={modalShown}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            {chooseBox()}
        </Modal>
    );
}

export default PrizeModal;
