export const answerKey = [
    {index: 114, timeStart: 19},
    {index: 2, timeStart: 14},
    {index: 109, timeStart: 23},
    {index: 99, timeStart: 22},
    {index: 96, timeStart: 16},
    {index: 93, timeStart: 27},
    {index: 106, timeStart: 12},
    {index: 104, timeStart: 18},
    {index: 95, timeStart: 16},
    {index: 88, timeStart: 42},
    {index: 80, timeStart: 10},
    {index: 86, timeStart: 36},
    {index: 94, timeStart: 10},
    {index: 97, timeStart: 15},
    {index: 87, timeStart: 47},
    {index: 101, timeStart: 18},
    {index: 107, timeStart: 20},
    {index: 78, timeStart: 38},
    {index: 12, timeStart: 360},
    {index: 105, timeStart: 15},
    {index: 21, timeStart: 318},
    {index: 71, timeStart: 242},
    {index: 74, timeStart: 18},
    {index: 95, timeStart: 16},
    {index: 102, timeStart: 28},
    {index: 14, timeStart: 157},
    {index: 110, timeStart: 7},
    {index: 5, timeStart: 3562},
    {index: 2, timeStart: 7477},
    {index: 18, timeStart: 145},
    {index: 55, timeStart: 122},
    {index: 98, timeStart: 20},
    {index: 103, timeStart: 7},
    {index: 89, timeStart: 188},
    {index: 19, timeStart: 81},
    {index: 36, timeStart: 369},
];

/**
 * fatiha
 * al kahf
 * al rahman
 * last two in albaqara
 * alkorsi
 * jozo2 3amma
 * DONE baqara :2
 * baqara 42
 * baqara 83
 * baqara 152
 * baqara 156
 * baqara 38
 * DONE al anbiya2 25
 * alnahl 2
 * al 3omran 26
 * al an3am 59
 * DONE yousef 18
 * DONE nuh 28
 * DONE almuddathir 8
 * alqiyamah 30
 * fasalat 34
 * alfajr 14
 * DONE ibrahim 7
 * 57:20
 * 12:110
 * 2:155
 * ys 82
 * azzamar 39:53
 * arra3d 13:28
 * attawbah 9:67
 *
 */