export const ensurahs = [
    { value: 0, label: "Al-Fatiha" },
    { value: 1, label: "Al-Baqarah" },
    { value: 2, label: "Al-Imran" },
    { value: 3, label: "An-Nisa" },
    { value: 4, label: "Al-Ma'idah" },
    { value: 5, label: "Al-An'am" },
    { value: 6, label: "Al-A'raf" },
    { value: 7, label: "Al-Anfal" },
    { value: 8, label: "At-Tawbah" },
    { value: 9, label: "Yunus" },
    { value: 10, label: "Hud" },
    { value: 11, label: "Yusuf" },
    { value: 12, label: "Ar-Ra'd" },
    { value: 13, label: "Ibrahim" },
    { value: 14, label: "Al-Hijr" },
    { value: 15, label: "An-Nahl" },
    { value: 16, label: "Al-Isra" },
    { value: 17, label: "Al-Kahf" },
    { value: 18, label: "Maryam" },
    { value: 19, label: "Ta-Ha" },
    { value: 20, label: "Al-Anbiya" },
    { value: 21, label: "Al-Hajj" },
    { value: 22, label: "Al-Mu'minun" },
    { value: 23, label: "An-Nur" },
    { value: 24, label: "Al-Furqan" },
    { value: 25, label: "Ash-Shu'ara" },
    { value: 26, label: "An-Naml" },
    { value: 27, label: "Al-Qasas" },
    { value: 28, label: "Al-Ankabut" },
    { value: 29, label: "Ar-Rum" },
    { value: 30, label: "Luqman" },
    { value: 31, label: "As-Sajdah" },
    { value: 32, label: "Al-Ahzab" },
    { value: 33, label: "Saba" },
    { value: 34, label: "Fatir" },
    { value: 35, label: "Ya-Sin" },
    { value: 36, label: "As-Saffat" },
    { value: 37, label: "Sad" },
    { value: 38, label: "Az-Zumar" },
    { value: 39, label: "Ghafir" },
    { value: 40, label: "Fussilat" },
    { value: 41, label: "Ash-Shura" },
    { value: 42, label: "Az-Zukhruf" },
    { value: 43, label: "Ad-Dukhan" },
    { value: 44, label: "Al-Jathiya" },
    { value: 45, label: "Al-Ahqaf" },
    { value: 46, label: "Muhammad" },
    { value: 47, label: "Al-Fath" },
    { value: 48, label: "Al-Hujurat" },
    { value: 59, label: "Qaf" },
    { value: 50, label: "Adh-Dhariyat" },
    { value: 51, label: "At-Tur" },
    { value: 52, label: "An-Najm" },
    { value: 53, label: "Al-Qamar" },
    { value: 54, label: "Ar-Rahman" },
    { value: 55, label: "Al-Waqi'ah" },
    { value: 56, label: "Al-Hadid" },
    { value: 57, label: "Al-Mujadilah" },
    { value: 58, label: "Al-Hashr" },
    { value: 59, label: "Al-Mumtahanah" },
    { value: 60, label: "As-Saff" },
    { value: 61, label: "Al-Jumu'ah" },
    { value: 62, label: "Al-Munafiqun" },
    { value: 63, label: "At-Taghabun" },
    { value: 64, label: "At-Talaq" },
    { value: 65, label: "At-Tahrim" },
    { value: 66, label: "Al-Mulk" },
    { value: 67, label: "Al-Qalam" },
    { value: 68, label: "Al-Haqqah" },
    { value: 69, label: "Al-Ma'arij" },
    { value: 70, label: "Nuh" },
    { value: 71, label: "Al-Jinn" },
    { value: 72, label: "Al-Muzzammil" },
    { value: 73, label: "Al-Muddaththir" },
    { value: 74, label: "Al-Qiyamah" },
    { value: 75, label: "Al-Insan" },
    { value: 76, label: "Al-Mursalat" },
    { value: 77, label: "An-Naba" },
    { value: 78, label: "An-Nazi'at" },
    { value: 79, label: "'Abasa" },
    { value: 80, label: "At-Takwir" },
    { value: 81, label: "Al-Infitar" },
    { value: 82, label: "Al-Mutaffifin" },
    { value: 83, label: "Al-Inshiqaq" },
    { value: 84, label: "Al-Buruj" },
    { value: 85, label: "At-Tariq" },
    { value: 86, label: "Al-A'la" },
    { value: 87, label: "Al-Ghashiyah" },
    { value: 88, label: "Al-Fajr" },
    { value: 89, label: "Al-Balad" },
    { value: 90, label: "Ash-Shams" },
    { value: 91, label: "Al-Layl" },
    { value: 92, label: "Ad-Duha" },
    { value: 93, label: "Ash-Sharh" },
    { value: 94, label: "At-Tin" },
    { value: 95, label: "Al-'Alaq" },
    { value: 96, label: "Al-Qadr" },
    { value: 97, label: "Al-Bayyinah" },
    { value: 98, label: "Az-Zalzalah" },
    { value: 99, label: "Al-'Adiyat" },
    { value: 100, label: "Al-Qari'ah" },
    { value: 101, label: "At-Takathur" },
    { value: 102, label: "Al-'Asr" },
    { value: 103, label: "Al-Humazah" },
    { value: 104, label: "Al-Fil" },
    { value: 105, label: "Quraish" },
    { value: 106, label: "Al-Ma'un" },
    { value: 107, label: "Al-Kauthar" },
    { value: 108, label: "Al-Kafirun" },
    { value: 109, label: "An-Nasr" },
    { value: 110, label: "Al-Masad" },
    { value: 111, label: "Al-Ikhlas" },
    { value: 112, label: "Al-Falaq" },
    { value: 113, label: "An-Nas" }
];