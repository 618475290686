import '../App.css';
import React, {useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import { logEvent } from "firebase/analytics";
import { getText } from "../Libraries/LangLib";

function ShareModal({modalShown, close, analytics, lang}) {

    const shareEvent = (social) => {
        logEvent(analytics, 'shared_link', {
            social: social
        });
    }

    const shareText = () => {
        let text = getText(lang, 'share', 0);
        text = text + '\n' + '🕋🕋' + '\n';
        return text;
    }

    const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '18em',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		outline: 0,
		textAlign: lang === 'ar' ? 'right' : 'left'
	};

    return (
        <Modal
            open={modalShown}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={modalStyle}>
                <h2 className="align-center">{getText(lang, 'share', 1)}</h2>
                <FacebookShareButton url='https://www.malayah.link/' quote={getText(lang, 'share', 0)}>
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <LinkedinShareButton url='https://www.malayah.link/' title={getText(lang, 'share', 0)}>
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <TwitterShareButton url='https://www.malayah.link/' title={getText(lang, 'share', 0)}>
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url='https://www.malayah.link/' title={shareText()}>
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </Box>
        </Modal>
    );
}

export default ShareModal;
