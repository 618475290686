export const surahs = [
    { value: 0, label: "الفاتحة" },
    { value: 1, label: "البقرة" },
    { value: 2, label: "آل عمران" },
    { value: 3, label: "النساء" },
    { value: 4, label: "المائدة" },
    { value: 5, label: "الأنعام" },
    { value: 6, label: "الأعراف" },
    { value: 7, label: "الأنفال" },
    { value: 8, label: "التوبة" },
    { value: 9, label: "يونس" },
    { value: 10, label: "هود" },
    { value: 11, label: "يوسف" },
    { value: 12, label: "الرعد" },
    { value: 13, label: "إبراهيم" },
    { value: 14, label: "الحجر" },
    { value: 15, label: "النحل" },
    { value: 16, label: "الإسراء" },
    { value: 17, label: "الكهف" },
    { value: 18, label: "مريم" },
    { value: 19, label: "طه" },
    { value: 20, label: "الأنبياء" },
    { value: 21, label: "الحج" },
    { value: 22, label: "المؤمنون" },
    { value: 23, label: "النور" },
    { value: 24, label: "الفرقان" },
    { value: 25, label: "الشعراء" },
    { value: 26, label: "النمل" },
    { value: 27, label: "القصص" },
    { value: 28, label: "العنكبوت" },
    { value: 29, label: "الروم" },
    { value: 30, label: "لقمان" },
    { value: 31, label: "السجدة" },
    { value: 32, label: "الأحزاب" },
    { value: 33, label: "سبأ" },
    { value: 34, label: "فاطر" },
    { value: 35, label: "يس" },
    { value: 36, label: "الصافات" },
    { value: 37, label: "ص" },
    { value: 38, label: "الزمر" },
    { value: 39, label: "غافر" },
    { value: 40, label: "فصلت" },
    { value: 41, label: "الشورى" },
    { value: 42, label: "الزخرف" },
    { value: 43, label: "الدخان" },
    { value: 44, label: "الجاثية" },
    { value: 45, label: "الأحقاف" },
    { value: 46, label: "محمد" },
    { value: 47, label: "الفتح" },
    { value: 48, label: "الحجرات" },
    { value: 49, label: "ق" },
    { value: 50, label: "الذاريات" },
    { value: 51, label: "الطور" },
    { value: 52, label: "النجم" },
    { value: 53, label: "القمر" },
    { value: 54, label: "الرحمن" },
    { value: 55, label: "الواقعة" },
    { value: 56, label: "الحديد" },
    { value: 57, label: "المجادلة" },
    { value: 58, label: "الحشر" },
    { value: 59, label: "الممتحنة" },
    { value: 60, label: "الصف" },
    { value: 61, label: "الجمعة" },
    { value: 62, label: "المنافقون" },
    { value: 63, label: "التغابن" },
    { value: 64, label: "الطلاق" },
    { value: 65, label: "التحريم" },
    { value: 66, label: "الملك" },
    { value: 67, label: "القلم" },
    { value: 68, label: "الحاقة" },
    { value: 69, label: "المعارج" },
    { value: 70, label: "نوح" },
    { value: 71, label: "الجن" },
    { value: 72, label: "المزمل" },
    { value: 73, label: "المدثر" },
    { value: 74, label: "القيامة" },
    { value: 75, label: "الانسان" },
    { value: 76, label: "المرسلات" },
    { value: 77, label: "النبأ" },
    { value: 78, label: "النازعات" },
    { value: 79, label: "عبس" },
    { value: 80, label: "التكوير" },
    { value: 81, label: "الإنفطار" },
    { value: 82, label: "المطففين" },
    { value: 83, label: "الإنشقاق" },
    { value: 84, label: "البروج" },
    { value: 85, label: "الطارق" },
    { value: 86, label: "الأعلى" },
    { value: 87, label: "الغاشية" },
    { value: 88, label: "الفجر" },
    { value: 89, label: "البلد" },
    { value: 90, label: "الشمس" },
    { value: 91, label: "الليل" },
    { value: 92, label: "الضحى" },
    { value: 93, label: "الشرح" },
    { value: 94, label: "التين" },
    { value: 95, label: "العلق" },
    { value: 96, label: "القدر" },
    { value: 97, label: "البينة" },
    { value: 98, label: "الزلزلة" },
    { value: 99, label: "العاديات" },
    { value: 100, label: "القارعة" },
    { value: 101, label: "التكاثر" },
    { value: 102, label: "العصر" },
    { value: 103, label: "الهمزة" },
    { value: 104, label: "الفيل" },
    { value: 105, label: "قريش" },
    { value: 106, label: "الماعون" },
    { value: 107, label: "الكوثر" },
    { value: 108, label: "الكافرون" },
    { value: 109, label: "النصر" },
    { value: 110, label: "المسد" },
    { value: 111, label: "الإخلاص" },
    { value: 112, label: "الفلق" },
    { value: 113, label: "الناس" }
];