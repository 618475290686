export const languages = {
    instructions: {
        ar: [
            'طريقة اللعب',
            'حاول تخمين السورة في أقل عدد من المحاولات الممكنة',
            'مع كل يوم تأتي تلاوة جديدة لآيات مختلفة',
            'اربح ل ١٥ يوم متتالي و أدخل السحبة للفوز بسجادة صلاة'
        ],
        indo: [
            'Cara Bermain',
            'Coba tebak Surah dengan jumlah tebakan sedikit mungkin',
            'Ayat yang dibaca akan berubah setiap hari',
            'Capai prestasi 15 hari berturut-turut dan masuk dalam undian untuk memenangkan sajadah shalat'
        ],
        en: [
            'How to play',
            'Try to guess the Surah in as few guesses as possible',
            'The recited Ayat will change every day',
            'Reach a streak of 15 days and enter the draw to win a prayer rug'
        ]
    },
    app: {
        ar: [
            'اكتب أو إختر السورة',
            'التالي',
            'لم يختار',
        ],
        indo: [
            'Cari atau pilih Surah',
            'Lewati',
            'Dilewatkan'
        ],
        en: [
            'Search or select the Surah',
            'Skip',
            'Skipped',
        ]
    },
    prize: {
        ar: [
          'رقم الهاتف وتساب',
          'أدخل رقم هاتفك الوتساب لنتصل بك في حال فوزك',
          'إرسال',
          'لتغيير رقم الهاتف، بإمكانك أن تتواصل معنا من خلال صفحتنا على فيسبوك',
          'اربح ل ١٥ يوم متتالي و أدخل السحبة للفوز بسجادة صلاة',
        ],
        indo: [
          'Nomor telepon WhatsApp',
          'Masukkan nomor telepon WhatsApp Anda agar kami dapat menghubungi Anda jika Anda memenangkan hadiah',
          'Kirim',
          'Untuk mengubah nomor yang telah dimasukkan, silakan hubungi kami di halaman Facebook kami',
          'Capai prestasi 15 hari berturut-turut dan masuk dalam undian untuk memenangkan sajadah shalat',
        ],
        en: [
          'WhatsApp phone number',
          'Enter your WhatsApp phone number so we can contact you if you win the prize',
          'Submit',
          'To change your entered number, please contact us on our Facebook page',
          'Reach a streak of 15 days and enter the draw to win a prayer rug',
        ]
    },
    streak: {
        ar: [
            'عدد الانتصارات',
            'إستمع للآيات و إحزر السورة',
            'سورة',
            'أحسنت',
            'حظ أوفر',
            'عدد الانتصارات المتتالية',
            'أعلى عدد انتصارات متتالية',
            'فزت ما الآية ب ', // p1
            ' محاولات اليوم',
        ],
        indo: [
            'Jumlah kemenangan',
            'Dengarkan Ayat dan tebak Surah-nya',
            '', // Kosong
            'Baik sekali',
            'Semoga beruntung!',
            'Streak saat ini',
            'Streak tertinggi yang tercatat',
            'Saya memenangkan Malayah dalam ', // p1
            ' percobaan hari ini',
        ],
        en: [
            'Number of wins',
            'Listen to the Ayat and guess the Surah',
            '', // Blank
            'Well done',
            "Better luck!",
            'Current streak',
            'Highest recorded streak',
            'I won Malayah in ', // p1
            ' attempts today',
        ],
    },
    share: {
        ar: [
            'إستمع للآيات و إحزر السورة',
            'ادعمونا و شاركوا ما الآية مع أصدقائكم',
        ],
        indo: [
            'Dengarkan Ayat dan tebak Surah-nya',
            'Bagikan Malayah dengan teman-temanmu'
        ],
        en: [
            'Listen to the Ayat and guess the Surah',
            'Share Malayah with your friends'
        ],
    }
}