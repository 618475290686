import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Select from 'react-select';
import InstructionsModal from './Components/InstructionsModal';
import { FaChartBar } from 'react-icons/fa';
import { FaQuestionCircle } from 'react-icons/fa';
import { FaPlayCircle } from 'react-icons/fa';
import { FaPauseCircle } from 'react-icons/fa';
import { FaShareAltSquare } from 'react-icons/fa';
import { FaTrophy } from 'react-icons/fa';
import { surahs } from './Surahs';
import { ensurahs } from './enSurahs';
import { generateAudio, getSurahIndex, getSurahNum } from './Libraries/Generator';
import StreakModal from './Components/StreakModal';
import GuessBoard from './Components/GuessBoard';
import ShareModal from './Components/ShareModal';
import PrizeModal from './Components/PrizeModal';
import { getText } from "./Libraries/LangLib";
import { initializeApp } from "firebase/app";
import { getAnalytics, setUserProperties, logEvent } from "firebase/analytics";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAeeuJV5J4gxCsEDluQnYt30S9IEscnmJA",
    authDomain: "malaya-d2881.firebaseapp.com",
    projectId: "malaya-d2881",
    storageBucket: "malaya-d2881.appspot.com",
    messagingSenderId: "780188250252",
    appId: "1:780188250252:web:0aa27bdedbb9f843250ba9",
    measurementId: "G-NQVZP9N735"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const userID = uuidv4();
setUserProperties(analytics, { userID: userID });

// TODO: Answer key. 20 seconds or longer?
// TODO: start date and firstYesterday
// TODO: X for modals?

function App() {
    const [guesses, setGuesses] = useState([]);
    const [streak, setStreak] = useState(0);
    const [highestStreak, setHighestStreak] = useState(0);
    const [allWins, setAllWins] = useState({0: 0, 1: 0, 2: 0, 3:0, 4:0, 5:0, 6:0});
    const [wonToday, setWonToday] = useState(false);
    const [instrModalShown, setInstrModalShown] = useState(false);
	const [strkModalShown, setStrkModalShown] = useState(false);
    const [shareModalShown, setShareModalShown] = useState(false);
    const [prizeModalShown, setPrizeModalShown] = useState(false);
    const [todaysAnswer, setTodaysAnswer] = useState(0);
    const [surahName, setSurahName] = useState('');
    //const [guessIndex, setGuessIndex] = useState(-1);
    const [sound, setSound] = useState(null);
    const [startTime, setStartTime] = useState(0);
    const [soundPlaying, setSoundPlaying] = useState(false);
    const [gameEnded, setGameEnded] = useState(false);
    const [selectedLang, setSelectedLang] = useState('ar');
    const [langSurahs, setLangSurahs] = useState(surahs);

    // milliseconds for each level
    const durations = [4000, 8000, 10000, 14000, 18000, 20000, 20000];

    var todaysDate = moment().format('MMDDYYYY');
	var yesterday = moment().subtract(1, 'days').format('MMDDYYYY');
    const firstYesterday = '03172023';
    //todaysDate = '03182023'
	//yesterday = '03172023';

    const handleStats = () => {
		setWonToday(true);
        setGameEnded(true);
        localStorage.setItem(todaysDate, 'true');
        localStorage.setItem('streak', streak + 1);
        // If streak is > highestStreak, set new highestStreak
        if (streak + 1 > highestStreak) {
            setHighestStreak(streak + 1);
            localStorage.setItem('highestStreak', streak + 1);
        }
        setStreak(streak + 1);
        logEvent(analytics, 'user_won', {
            answer: todaysAnswer,
            date: todaysDate,
            guesses: JSON.stringify(guesses),
            numGuesses: guesses.length,
            streak: streak
        });
        // Handle prize entry
        let firstPrize = handlePrizeEntry(streak + 1);
        if (firstPrize) {
            setPrizeModalShown(true);
        } else {
            setStrkModalShown(true);
        }
        // Update total wins
        handleAllWins();
	}

    const handlePrizeEntry = () => {
        let currStreak = getStreak();
        let alreadyEnteredPrize = localStorage.getItem('prize_entered');
        if (currStreak >= 15 && alreadyEnteredPrize === null) {
            return true;
        }
        return false;
    }

    const handleGuess = (selectedOption) => {
		localStorage.setItem('todaysGuesses_' + todaysDate, JSON.stringify([...guesses, selectedOption]));
		setGuesses([...guesses, selectedOption]);
        // User won
        if (selectedOption.value === todaysAnswer) {
            // Pause if won
            sound.pause();
            sound.currentTime = startTime;
            setSoundPlaying(false);
            handleStats();
        } else if (guesses.length + 1 === 6) {
            setGameEnded(true);
            // Pause if lost
            sound.pause();
            sound.currentTime = startTime;
            setSoundPlaying(false);
            localStorage.setItem('streak', 0)
            setStreak(0);
            setStrkModalShown(true);
            logEvent(analytics, 'user_lost', {
                answer: todaysAnswer,
                date: todaysDate,
                guesses: guesses
            });
        }
        logEvent(analytics, 'guess_attempted', {
            answer: todaysAnswer,
            date: todaysDate,
            guess: selectedOption.value,
            numGuesses: guesses.length,
            guesses: JSON.stringify(guesses)
        });
    }

    const handleAllWins = () => {
        let historicalWins = allWins;
        historicalWins[guesses.length] = historicalWins[guesses.length] + 1;
        setAllWins(historicalWins);
        localStorage.setItem('allWins', JSON.stringify(historicalWins));
    }

    // Open instructions modal
	const openInstrModal = () => {
		setInstrModalShown(true);
		logEvent(analytics, 'open_instructions');
	};

	// Close instructions modal
	const closeInstrModal = () => {
		setInstrModalShown(false);
	}

    // Open sharing modal
	const openShareModal = () => {
		setShareModalShown(true);
		logEvent(analytics, 'open_share');
	};

	// Close sharing modal
	const closeShareModal = () => {
		setShareModalShown(false);
	}

	// Open streak modal
	const openStrkModal = () => {
		setStrkModalShown(true);
		logEvent(analytics, 'open_stats');
	};

	// Close streak modal
	const closeStrkModal = () => {
		setStrkModalShown(false);
	}

    // Open prize modal
	const openPrizeModal = () => {
		setPrizeModalShown(true);
		logEvent(analytics, 'open_prize');
	};

	// Close prize modal
	const closePrizeModal = () => {
		setPrizeModalShown(false);
	}

    // Handle Skip button
    const handleSkip = () => {
        if (guesses.length <= 5)
        handleGuess({value: -1, label: getText(selectedLang, 'app', 2)});
    }

    // Check if user ID is in localStorage and show instructions based on that
	const showInstructionsFirstTime = () => {
		let localUserID =  localStorage.getItem('userID');
		if (localUserID === null) {
			localStorage.setItem('userID', userID);
			setInstrModalShown(true);
		}
	}

    // Get user's streak
	const getStreak = () => {
		let wonYesterday =  localStorage.getItem(yesterday);
		let currStreak = localStorage.getItem('streak') ?? 1;
        if (yesterday === firstYesterday && localStorage.getItem(todaysDate)) {
            localStorage.setItem('streak', currStreak );
            return 1;
        } else if (wonYesterday === null) {
			localStorage.setItem('streak', 0);
			return 0;
		} else {
			localStorage.setItem('streak', currStreak );
			return parseInt(currStreak);
		}
	}

    // Get user's highest streak
	const getHighestStreak = () => {
		let highest =  localStorage.getItem('highestStreak');
		if (highest === null) {
			return 0;
		}
		return parseInt(highest);
	}

    // Get all of the user's win by attempts => success
	const getAllWins = () => {
		let historicalWins =  localStorage.getItem('allWins');
		if (historicalWins === null) {
            return allWins;
        }
        return JSON.parse(historicalWins);
	}

    const clearYesterday = () => {
		localStorage.setItem('todaysGuesses_' + todaysDate, JSON.stringify([]));
		//localStorage.setItem('guesses', 0);
		setGuesses([]);
		// Correct will be set to [] when returning this function
	};

    // Get today's guesses
	const getGuesses = (answer) => {
		let todaysGuesses =  localStorage.getItem('todaysGuesses_' + todaysDate);
		if (todaysGuesses === null) {
			return [];
		}
		let localAnswer = localStorage.getItem('answer');
        todaysGuesses = JSON.parse(todaysGuesses);
		if (parseInt(localAnswer) === parseInt(answer)) {
            if (localStorage.getItem(todaysDate)) {
                setWonToday(true);
                setGameEnded(true);
            } else if (todaysGuesses.length >= 6) {
                setGameEnded(true);
            }
			return todaysGuesses;
		} else {
			clearYesterday();
			return [];
		}
	}

    // Populate the guess fields
    const getGuessText = (index) => {
        index = parseInt(index);
        if (guesses.length === 0 || guesses.length <= index || guesses == null) {
            return '';
        }
        return guesses[index]['label'];
    }

    // Handle sound
    const handleSound = () => {
        if (soundPlaying) {
            sound.pause();
            sound.currentTime = startTime;
            setSoundPlaying(!soundPlaying);
        } else {
            sound.currentTime = startTime;
            sound.play();
            setSoundPlaying(!soundPlaying);
            setTimeout(() => {
                sound.pause();
                sound.currentTime = startTime;
                setSoundPlaying(false);
            }, durations[guesses.length]);
        }

    }

    // Prepare sound
    const getSound = () => {
        let [audio, timeStart] = generateAudio();
        setSound(audio);
        setStartTime(parseInt(timeStart));
    }

    // Get Answer
    const getAnswer = () => {
        let answer = getSurahNum();
        answer--; // To adjust for index
        setTodaysAnswer(answer);
        setSurahName(langSurahs[answer]['label']);
        // if the answer has been updated, this means we should clear the current answers
		setGuesses(getGuesses(answer));
		localStorage.setItem('answer', answer);
    }

    // Get selected lang, if exists
    const getLang = () => {
        let storedLang =  localStorage.getItem('lang');
		if (storedLang === null) {
            storedLang = 'ar';
			setSelectedLang('ar');
		} else {
            setSelectedLang(storedLang);
        }
        // Set the surahs
        if (storedLang === 'ar') {
            setLangSurahs(surahs);
        } else {
            setLangSurahs(ensurahs)
        }
    }

    useEffect(() => {
        // TODO comment out
		//localStorage.clear();
        getLang();
		showInstructionsFirstTime();
        // Generate today's answer
        getAnswer();
        getSound();
		setStreak(getStreak());
		setHighestStreak(getHighestStreak());
		setAllWins(getAllWins());
	}, []);

    const isSkippedStyle = (index) => {
        if (getGuessText(index) === getText(selectedLang, 'app', 2)) {
            return {'color' : 'rgba(85, 181, 179, 0.5)'};
        }
        return {};
    }

    return (
        <div className="App">
            <div className="top-bar">
                <FaQuestionCircle className="question-icon" onClick={openInstrModal} />
                <FaShareAltSquare className="share-icon" onClick={openShareModal} />
                <p className='title'>ما الآية؟</p>
                <FaChartBar className="chart-icon" onClick={openStrkModal} />
                <FaTrophy className="prize-icon" onClick={openPrizeModal} />
            </div>
            <div className="App-header">
                <div className='guesses-div'>
                    <div className='guess-div' style={isSkippedStyle(0)}>
                        <p>{getGuessText(0)}</p>
                    </div>
                    <div className='guess-div' style={isSkippedStyle(1)}>
                        <p>{getGuessText(1)}</p>
                    </div>
                    <div className='guess-div' style={isSkippedStyle(2)}>
                        <p>{getGuessText(2)}</p>
                    </div>
                    <div className='guess-div' style={isSkippedStyle(3)}>
                        <p>{getGuessText(3)}</p>
                    </div>
                    <div className='guess-div' style={isSkippedStyle(4)}>
                        <p>{getGuessText(4)}</p>
                    </div>
                    <div className='guess-div' style={isSkippedStyle(5)}>
                        <p>{getGuessText(5)}</p>
                    </div>
                </div>
                <div className='player'>
                    {!soundPlaying && <FaPlayCircle className="play-icon" onClick={handleSound} /> }
                    {soundPlaying && <FaPauseCircle className="play-icon" onClick={handleSound} /> }
                </div>
                <GuessBoard numGuesses={guesses.length} playing={soundPlaying} />
                <div className='select-div'>
                <Select options={langSurahs} menuPlacement="top" onChange={handleGuess} isDisabled={gameEnded} maxMenuHeight={'10rem'} placeholder={getText(selectedLang, 'app', 0)}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#224247',
                      primary: '#55b5b3',
                    },
                })}
                styles={{
                    control: (styles, state) => ({ ...styles, backgroundColor: state.isDisabled ? '#787878' : 'black', borderColor: '#55b5b3', }),
                    container: (baseStyles, state) => ({
                        ...baseStyles,
                        width: '21rem',
                        color: 'white',
                    }),
                    menu: (styles) => ({ ...styles, backgroundColor: 'black', border: '1px solid #55b5b3', color: 'white', textAlign: 'center'}),
                    singleValue: (styles) => ({ ...styles, color: 'white', textAlign: 'center', marginLeft: '2rem'  }),
                    input: (styles) => ({ ...styles, textAlign: 'right', color: 'white', }),
                }}
                />
                </div>
                <div className='skip-div'>
                    <button className="skip-button" value="skip" onClick={handleSkip} disabled={gameEnded}>{getText(selectedLang, 'app', 1)}</button>
                </div>
            </div>
            <InstructionsModal modalShown={instrModalShown} close={closeInstrModal} lang={selectedLang} setLang={setSelectedLang} changeSurahs={setLangSurahs} changeSurahName={setSurahName} answerIndex={todaysAnswer} analytics={analytics} />
            <StreakModal modalShown={strkModalShown} close={closeStrkModal} won={wonToday} streak={streak} highestStreak={highestStreak} answer={surahName} gameEnded={gameEnded} allWins={allWins} answerIndex={todaysAnswer + 1} analytics={analytics} numGuesses={guesses.length} lang={selectedLang} />
            <ShareModal modalShown={shareModalShown} close={closeShareModal} analytics={analytics} lang={selectedLang} />
            <PrizeModal modalShown={prizeModalShown} close={closePrizeModal} analytics={analytics} streak={getStreak()} lang={selectedLang} />
        </div>
    );
}

export default App;
