import { answerKey } from "../AnswerKey";


export const generateAudio = () => {
    //let audio = new Audio('https://cdn.islamic.network/quran/audio/128/ar.alafasy/6201.mp3');
    let index = getTodaysIndex();
    let surahNum = answerKey[index]['index'];
    let audio = new Audio('https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/' + surahNum + '.mp3');
    return [audio, answerKey[index]['timeStart']];
}

export const getSurahNum = () => {
    let index = getTodaysIndex();
    let surahNum = answerKey[index]['index'];
    return surahNum;
}

const getTodaysIndex = () => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const startDate = new Date(2023, 2, 18); // Months start at 0
    const today = new Date();
    let diffDays = Math.floor(Math.abs((startDate - today) / oneDay));
    //console.log(diffDays + ' keylength ' + answerKey.length);
    diffDays = diffDays % answerKey.length;
    //console.log(diffDays + ' diff');
    return diffDays;
}

export const getSurahIndex = async () => {
    try {
        const response = await fetch("http://api.alquran.cloud/v1/ayah/262/ar.alafasy", {
            method: "GET"
        });
        let result = await response.json();
        //console.log(result);
        result = result['data']['surah']['number'];
        // Index is + 1, so we need to subtract
        return result - 1;
    } catch(error) {
        console.log(error);
    };
    //let json;
    //await fetch("http://api.alquran.cloud/v1/ayah/262/ar.alafasy")
    //.then((response) => response.json())
    //.then((data) => json);
    //console.log(json);
}