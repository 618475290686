import '../App.css';
import React, {useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { logEvent } from "firebase/analytics";
import { getText } from "../Libraries/LangLib";
import { surahs } from '../Surahs';
import { ensurahs } from '../enSurahs';
import dhad from '../Assets/dhad.png';
import indonesia from '../Assets/indonesia.png';
import unitedStates from '../Assets/unitedStates.png';
import {closeModalStyle} from '../Libraries/StyleLib';
import { FaRegTimesCircle } from 'react-icons/fa';


function InstructionsModal({modalShown, close, lang, setLang, changeSurahs, changeSurahName, answerIndex, analytics}) {
    const [localLang, setLocalLang] = useState(lang);

    const changeLangEvent = (newLang) => {
        logEvent(analytics, 'changed_lang', {
            chosenLang: newLang
        });
    }

    const handleLang = (newLang) => {
        setLang(newLang);
        setLocalLang(newLang);
        localStorage.setItem('lang', newLang);
        if (newLang === 'ar') {
            changeSurahs(surahs);
            changeSurahName(surahs[answerIndex]['label']);
        } else {
            changeSurahs(ensurahs)
            changeSurahName(ensurahs[answerIndex]['label']);
        }
        changeLangEvent(newLang);
    }

    const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '18em',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		outline: 0,
		textAlign: lang === 'ar' ? 'right' : 'left'
	};

    const selectButtonStyle = (passedLang) => {
        let selected = passedLang === lang;
        let style = {
            background: selected ? 'rgba(85, 181, 179, 0.5)' : 'none',
            border: selected ? '1px solid rgba(85, 181, 179, 0.5)' : '0px',
            width: '4rem',
            height: '4rem'
        }
        return style;

    }

    return (
        <Modal
            open={modalShown}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={modalStyle}>
                <FaRegTimesCircle onClick={close} style={closeModalStyle(lang)} />
                <h2 className="align-center">{getText(lang, 'instructions', 0)}</h2>
                <p>{getText(lang, 'instructions', 1)}</p>
                <p>{getText(lang, 'instructions', 2)}</p>
                <h1>🏆🏆</h1>
                <p>{getText(lang, 'instructions', 3)}</p>
                <button style={selectButtonStyle('ar')} value="ar" onClick={() => handleLang('ar')}>
                    <img src={dhad} alt="Arabic" className='lang-icon' />
                </button>
                <button style={selectButtonStyle('indo')} value="indo" onClick={() => handleLang('indo')}>
                    <img src={indonesia} alt="Indonesia" className='lang-icon' />
                </button>
                <button style={selectButtonStyle('en')} value="en" onClick={() => handleLang('en')}>
                    <img src={unitedStates} alt="English" className='lang-icon' />
                </button>
            </Box>
        </Modal>
    );
}

export default InstructionsModal;
