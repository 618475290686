import '../App.css';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FaVolumeUp } from 'react-icons/fa';
import { logEvent } from "firebase/analytics";
import {WhatsappShareButton, WhatsappIcon} from "react-share";
import { getText } from "../Libraries/LangLib";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

function StreakModal({ modalShown, close, won, streak, highestStreak, answer, gameEnded, allWins, answerIndex, analytics, numGuesses, lang }) {

    const data = {
        labels: [1, 2, 3, 4, 5, 6],
        datasets: [
            {
                label: getText(lang, 'streak', 0),
                data: Object.values(allWins),
                backgroundColor: [
                    'rgba(75, 107, 214, 0.5)',
                    'rgba(169, 191, 138, 0.5)',
                    'rgba(208, 247, 217, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(97, 218, 251, 0.5)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '18em',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        outline: 0,
        textAlign: lang === 'ar' ? 'right' : 'left'
    };

    const options = {
        plugins: {
            datalabels: {
                color: 'black',
                labels: {
                    title: {
                        font: {
                            weight: 'bold'
                        }
                    },
                    value: {
                        color: 'black'
                    }
                },
                formatter: (val, ctx) => (ctx.chart.data.labels[ctx.dataIndex]),
            },
            legend: {
                display: false
            },
        },
    };

    const openInNewTab = () => {
		logEvent(analytics, 'opened_audio_new_tab');
        let url = 'https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/' + answerIndex + '.mp3';
    	window.open(url, '_blank', 'noopener,noreferrer');
	};

    const shareText = () => {
        if (won) {
            let text = getText(lang, 'streak', 7) + numGuesses  + getText(lang, 'streak', 8);
            text = text + '\n' + '🕋🕋' + '\n';
            return text;
        }
        let text = getText(lang, 'streak', 1);
        text = text + '\n' + '🕋🕋' + '\n';
        return text;
    }

    const shareEvent = () => {
        logEvent(analytics, 'shared_whatsapp_from_streak');
    }

    const endResStyle = () => {
        let style = {
            display: 'flex',
            flexDirection: 'row',
            flexDirection: lang === 'ar' ? 'row-reverse' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        }
        return style;
    }

    return (
        <Modal
            open={modalShown}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                {gameEnded &&
                <div className='surah-name-div'>
                    <h1 className="align-center winning-text">{getText(lang, 'streak', 2)} {answer}</h1>
                    <FaVolumeUp className="volume-icon" onClick={openInNewTab} />
                </div>
                }
                {gameEnded && <hr />}
                <div style={endResStyle()}>
                    {won && <h2 className="align-center result-message">💥 {getText(lang, 'streak', 3)} 💥</h2>}
                    {gameEnded && !won && <h2 className="align-center result-message">🤲 {getText(lang, 'streak', 4)} 🤲</h2>}
                    {gameEnded && <WhatsappShareButton url='https://www.malayah.link/' title={shareText()}>
                        <WhatsappIcon size={32} round className='result-message' onClick={shareEvent} />
                    </WhatsappShareButton>}
                </div>
                <div className="">
                    <p className="modal-stat-text">{getText(lang, 'streak', 5)}</p>
                    <button className="stats-button-blue" value="0">{streak}</button>
                </div>
                <div className="">
                    <p className="modal-stat-text">{getText(lang, 'streak', 6)}</p>
                    <button className="stats-button-green" value="0">{highestStreak}</button>
                </div>
                <div style={{display:'flex', maxHeight: '45%', justifyContent: 'center'}}><PolarArea data={data} options={options} /></div>

            </Box>
        </Modal>
    );
}

export default StreakModal;
