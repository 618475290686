import '../App.css';
import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


function GuessBoard({numGuesses, playing}) {

    const [durations, setDuration] = useState({0: 20, 1: 40, 2: 50, 3: 70, 4: 90, 5: 100, 6: 100});
    const [progress, setProgress] = useState(0);
    const timeOutVals = [180, 190, 192, 195, 200, 200, 200];

    useEffect(() => {
        if (playing) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    return (oldProgress + 1);
                });
              }, timeOutVals[numGuesses]);
              return () => {
                clearInterval(timer);
              };
        } else {
            setProgress(0);
        }
      }, [playing]);

    const setAdditionalStyling = (index) => {
        let standard = {
            width: '2rem',
            height: '1rem',
            border: '1px solid #aaf2d8',
        };
        return standard;
    }

    const setStyle = () => {
        //console.log(durations[numGuesses]);
        let percentage = durations[numGuesses];
        let standard = {
            width: '21rem',
            height: '1rem',
            border: '1px solid #aaf2d8',
            background: 'linear-gradient(to right, #aaf2d8 ' + percentage + '%, grey ' + percentage + '% 100%)',
            //backgroundColor: 'green',
            color: 'red',
            '& .MuiLinearProgress-bar': {
                backgroundColor: '#55b5b3'
            },
        };
        return standard;
    }

    return (
        <div className='guessboard-div'>
            {/*<div className='single-player-div' style={setAdditionalStyling(0)}></div>
            <div className='single-player-div' style={setAdditionalStyling(1)}></div>
            <div className='single-player-div' style={setAdditionalStyling(2)}></div>
            <div className='single-player-div' style={setAdditionalStyling(3)}></div>
            <div className='single-player-div' style={setAdditionalStyling(4)}></div>
            <div className='single-player-div' style={setAdditionalStyling(5)}></div>*/}
            <Box>
                <LinearProgress variant="determinate" value={progress} sx={setStyle()} />
            </Box>
        </div>
    );
}

export default GuessBoard;
